import React, { Component } from 'react'
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import SpeakerImage from '../speaker-image'

class Introduction extends Component {
  render() {
    return (
      <div className="py-5 bg-gray-100">
        <div className="container">
          <div className="row">
            <div className="col-md-8 body-content font-size-sm">
              <h2>
                <span className="bg-blue text-white px-1 rounded">
                  About Sergio
                </span>
              </h2>
              <p className="lead">Engineering Director in Nashville, TN.</p>

              <p>
                Hey there{' '}
                <span role="img" aria-label="Waving hand">
                  👋
                </span>
                ! I’m a hands-on technology leader who is passionate about
                helping software engineering teams ship beautiful products with
                high-quality code. I currently lead cross-functional squads with
                direct reports who are technical individual contributors and
                engineering managers.
              </p>

              <p>
                Before getting into tech leadership, I spent over a decade
                working as a software engineer for startups and enterprises. My
                path to getting into the industry was not traditional: I taught
                myself to code and got my first job as a developer when I was
                still a teenager. In fact, I was the only developer I knew until
                I went to my first tech conference at the age of 17. That was
                when I learned that there was a whole community behind the
                makers of software, and I was determined to be part of it.
                Little did I know that a seed was planted back then, and I
                started growing a passion for sharing my technical knowledge
                with others. I later went to college to formalize my skills, but
                by then I had been working full-time as an engineer for years.
              </p>

              <p>
                Fast forward to today and I still love to share my knowledge
                with others. I{' '}
                <OutboundLink
                  href="https://www.pluralsight.com/courses/code-school-powering-up-with-react"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  teach online courses
                </OutboundLink>{' '}
                and give talks at meetups and{' '}
                <OutboundLink
                  href="https://youtu.be/WHMyaTy7PME"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  tech conferences
                </OutboundLink>
                . I also mentor folks on how to break into our industry because
                I know how daunting a task that is. I was thrilled when I was
                first given the opportunity to mentor other engineers as part of
                my day-job. Back then, I was given the title of Tech Lead and
                was told to set the technical direction for the team and mentor
                others along the way. That was the beginning of my tech
                leadership career.
              </p>

              <p>
                As a tech leader, I strive to create environments where software
                engineering teams can thrive by being productive, but also by
                expressing their creativity through code. One of the ways I do
                that is by making it safe to make mistakes as long as we’re
                learning from them. That typically means we’re pushing the
                envelope and trying new things. That's a must-have in our
                ever-evolving industry!
              </p>

              <p>
                Want to talk more about building products or leading tech teams?
                Here's my contact info:
                <br />
                <strong>E.</strong> hello [at] sergiocruz.me
                <br />
                <strong>P.</strong> (407) six-nine-four 8175
              </p>

              <p className="mt-4 mb-md-0">
                <Link
                  to="/resume"
                  className="btn btn-outline-blue extra-rounded px-4"
                >
                  View resume
                </Link>
              </p>
            </div>
            <div className="col-md-4">
              <SpeakerImage
                className="img-fluid rounded mt-3 mt-md-0"
                alt="Sergio speaking at ng-conf 2018"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Introduction
